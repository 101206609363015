<script lang="ts">
  export let size: 'xsmall' | 'small' | 'medium' | 'large' | 'hero' = 'small',
    variant: 'primary' | 'secondary' | 'danger' | 'link' = 'primary',
    disabled: boolean = false
</script>

<button
  on:click
  class:primary={variant === 'primary'}
  class:secondary={variant === 'secondary'}
  class:danger={variant === 'danger'}
  class:link={variant === 'link'}
  class:xsmall={size === 'xsmall'}
  class:medium={size === 'medium'}
  class:large={size === 'large'}
  class:hero={size === 'hero'}
  {disabled}
>
  <slot />
</button>

<style>
  button {
    background: var(--background-color);
    color: var(--secondary-text-color);
    border: 0;
    padding: 0.375rem 0.5rem;
    font-size: var(--text-xs);
    font-weight: 450;
    border-radius: 3px;
    line-height: 14px;
  }
  button.xsmall {
    padding: 0.25rem 0.375rem;
  }
  button.medium {
    padding: 0.5rem 0.62rem;
    font-size: 14px;
  }
  button.large {
    font-size: 15px;
    padding: 0.75rem 1rem;
    border-radius: 4px;
  }
  button.hero {
    font-size: 18px;
    padding: 1rem 2rem;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
  }
  button.primary {
    background: var(--accent-color);
    color: #fff;
  }
  button.primary:hover {
    background: var(--accent-color-hover);
    color: #fff;
  }
  button.link:hover {
    color: var(--accent-color-hover);
    cursor: pointer;
  }
  button.danger {
    background: var(--error-color);
    color: #fff;
  }
  button.danger:hover {
    background: var(--error-color-hover);
  }
  button.secondary {
    background: var(--tertiary-color);
  }
  button.secondary:hover {
    background: var(--tertiary-color-hover);
  }
  button:focus-visible {
    box-shadow: 0 0 0 2px var(--accent-color) inset;
  }
  button.primary:focus-visible {
    box-shadow: 0 0 0 2px var(--accent-color-hover) inset;
  }
  button:disabled {
    background: #888;
  }
  button:active:not(:disabled) {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3) inset;
  }
</style>
